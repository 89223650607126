/** @jsxRuntime classic */
/** @jsx jsx */
import React, {useEffect, useState} from 'react';
import {css, jsx} from '@emotion/react';
import {ArchivePlayer} from '../components/widget-adapter/ArchivePlayer';
import {parseHashAndSearch, isString} from '../utils/helpers';
import {getStream} from '../requests/getStream';
import {useApiRepository} from '../hooks/useApiRepository';

export const ArchiveWrap = () => {
  const {api} = useApiRepository();
  const searchParamsString = decodeURIComponent(new URL(window.location.href).search).replace(/.*\?/, '?');
  const query = parseHashAndSearch(searchParamsString);
  const [cameraId, setCameraId] = useState<string | ''>('');
  const [initialTime, setInitialTime] = useState<number | undefined>(undefined);

  const cameraIdFromUrl = isString(query.cameraId) ? query.cameraId : undefined;
  const streamId = isString(query.streamId) ? query.streamId : undefined;
  const initialTimeFromUrl = isString(query.initialTime) ? parseInt(query.initialTime, 10) : undefined;

  useEffect(() => {
    const fetchCameraId = async () => {
      try {
        if (streamId) {
          const stream = await getStream(api, streamId);
          if (stream?.cameraId) {
            setCameraId(stream.cameraId);
          } else {
            console.error('Camera ID not found for stream:', streamId);
          }
        } else if (cameraIdFromUrl) {
          setCameraId(cameraIdFromUrl);
        } else {
          console.error('No cameraId or streamId found in URL');
        }
      } catch (error) {
        console.error('Error fetching camera ID:', error);
      }
    };

    fetchCameraId();

    if (initialTimeFromUrl !== undefined) {
      setInitialTime(initialTimeFromUrl);
    }
  }, [api, streamId, cameraIdFromUrl, initialTimeFromUrl]);

  return (
    <div css={archiveWrapStyles}>
      <ArchivePlayer cameraId={cameraId} initialTime={initialTime} />
    </div>
  );
};

const archiveWrapStyles = css`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;
